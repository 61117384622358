<template>
    <div class="border-b">
        <p v-if="clientData.archived" class="py-2 mb-3 text-sm text-center bg-yellow-500 text-white rounded">
            {{ $t('common.client_archived') }}
        </p>
        <p class="h-10 mb-2 text-center border-b">
            {{ clientData.client_name }} {{ clientData.client_surname }}
        </p>
        <div class="flex pb-2">
            <img class="mr-1" :src="clientData.icon">
            <div class="mt-1 space-y-2">
                <p class="text-xs text-red-700 uppercase">
                    {{ clientData.client_type && $t(`clients.${clientData.client_type}`) }} {{ $t(`clients.client`) }}
                </p>
                <el-tooltip v-model="toolTipVisible" :content="$t('copied')" placement="bottom" manual>
                    <p id="emailLink" class="clipboardjs cursor-pointer text-xs text-red-700 overflow-ellipsis overflow-hidden w-[160px]" :title="clientData.client_email" @click="copyEmailToClipBoard" data-clipboard-target="#emailLink">
                        {{ clientData.client_email }}
                    </p>
                </el-tooltip>
                <p class="text-xs text-red-700">
                    {{ clientData.client_telephone }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import ClipboardJS from 'clipboard';

export default {
    props: {
        clientData: {
            type:    Object,
            default: () => ({
                client_name:      '',
                client_surname:   '',
                icon:             '',
                client_type:      '',
                client_email:     '',
                client_telephone: '',
            }),
        },
    },

    data() {
        return {
            toolTipVisible:    false,
            clipboardjsObject: new ClipboardJS('.clipboardjs'),
        };
    },

    beforeDestroy() {
        this.clipboardjsObject.destroy();
    },

    methods: {
        copyEmailToClipBoard() {
            this.toolTipVisible = true;
            setTimeout(() => {
                this.toolTipVisible = false;
            }, 1000);
        },
    },
};
</script>
